const routerMixin = {
  methods: {
    goTo (instance) {
      const user = instance.getUser()
      if (instance.isCustomer(user.roleId)) {
        return {
          path: '/ferias/' + user.fairId + '/reservas/' + user.bookingId + '/confirmar'
        }
      } else if (instance.isAdmin(user.roleId)) {
        return { path: '/reservas' }
      } else if (instance.isSuperExpositorAdmin(user.roleId)) {
        return { path: '/expositores/reserva-charlas' }
      } else if (instance.isExpositorAdmin(user.roleId)) {
        return { path: '/expositores/visitantes' }
      } else if (instance.isConferenceAdmin(user.roleId)) {
        return { path: '/expositores/reserva-charlas' }
      } else {
        return { path: '/ferias' }
      }
    }
  }
}

export default routerMixin
